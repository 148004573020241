import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { message, Skeleton, Card } from 'antd';
import { Switch, Route } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import route from '@/config/route';
import { ErrorBoundary } from 'react-error-boundary';

class App extends React.Component {
    UNSAFE_componentWillReceiveProps(nextProps) {
        // 判断跳转路由不等于当前路由
        if (
            nextProps.location.pathname !== this.props.location.pathname ||
            nextProps.location.search !== this.props.location.search ||
            nextProps.menu !== this.props.menu
        ) {
            const currentItem = this.findItemByPathName(nextProps.location.pathname, nextProps.menu);
            const activeUrl = nextProps.location.pathname;
            const activeKey = currentItem.path;
            // 不跳转改成提示
            if (!currentItem) {
                message.error('no permission or page not fond');
                console.log(nextProps.location.pathname);
                window.location.hash = '/';
                return;
            }
            if (currentItem.key) {
                const tabs = nextProps.tabs || this.props.tabs;
                const newtabs = tabs.find((item) => item.pathname === activeUrl)
                    ? tabs
                    : [
                          {
                              //设置tab的title 优先获取路由的state.title
                              title:
                                  nextProps.location.state?.title ||
                                  (currentItem.key && (
                                      <>
                                          <FormattedMessage id={currentItem.key} />
                                          {currentItem.slot}
                                      </>
                                  )),
                              key: activeUrl,
                              url: window.location.href,
                              search: window.location.search,
                              pathname: activeUrl,
                          },
                          ...tabs,
                      ];
                nextProps.dispatch({
                    type: 'SET_TAB',
                    payload: { tabs: newtabs, currentTab: activeUrl },
                });
                !currentItem.hidden &&
                    nextProps.dispatch({
                        type: 'SET_ACTIVEKEY',
                        payload: { activeKey },
                    });
            }
        }
    }
    findItemByPathName(pathName, menu) {
        if (!menu) {
            return;
        }
        for (let item of menu) {
            if (item.path === pathName || new RegExp(`^${item.path}$`, 'g').test(pathName)) {
                return item;
            }
            let res = this.findItemByPathName(pathName, item.children);
            if (res) {
                return res;
            }
        }
        return false;
    }

    fallbackRender({ error, resetErrorBoundary }) {
        return (
            <Card>
                <p>Something went wrong:</p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
            </Card>
        );
    }

    render() {
        return (
            <ErrorBoundary fallbackRender={this.fallbackRender}>
                <Suspense
                    fallback={
                        <div
                            style={{
                                background: '#fff',
                                padding: 40,
                                height: '100%',
                            }}
                        >
                            <Skeleton active />
                            <Skeleton active style={{ marginTop: 60 }} />
                        </div>
                    }
                >
                    <Switch style={{ height: '100%' }}>
                        {route.map((route) => (
                            <Route path={route.path} component={route.component} key={route.path} />
                        ))}
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = (state) => ({
    menu: state.common.menu,
    tabs: state.common.tabs,
});

let HubComponent = connect(mapStateToProps)(App);
export default withRouter(HubComponent);
