const transactionFee = {
    'transactionFee.title': '出入账手续费',

    'transactionFee.feeStrategy.title': '手续费策略',
    'transactionFee.categoryID': '分类ID',
    'transactionFee.categoryID.placeholder': '请输入分类ID',
    'transactionFee.feeType': '费用类型',
    'transactionFee.feeType.placeholder': '请选择费用类型',
    'transactionFee.feeType.OutboundTransactionOn-Chain': '出账上链',
    'transactionFee.feeType.OutboundTransactionCompliance': '出账合规',
    'transactionFee.feeType.OutboundTransactionGrossProfit': '出账毛利',
    'transactionFee.feeType.InboundTransactionCollection': '入账归集',
    'transactionFee.feeType.InboundTransactionCompliance': '入账合规',
    'transactionFee.feeType.InboundTransactionGrossProfit': '入账毛利',
    'transactionFee.mainchainCoinFee': '费用（主链币）',
    'transactionFee.tokenCoinFee': '费用（Token币）',
    'transactionFee.strategyPurpose': '策略目标',
    'transactionFee.status.activate': '已启用',
    'transactionFee.status.deactivate': '已禁用',

    'transactionFee.groupManagement.title': '机构分类管理',
    'transactionFee.groupManagement.groupDes': '分类说明',
    'transactionFee.groupManagement.groupDes.placeholder': '请输入分类说明',
    'transactionFee.groupManagement.create': '新建分类',
    'transactionFee.groupManagement.edit': '编辑分类',
    'transactionFee.groupManagement.lastOp': '最近操作人',
    'transactionFee.groupManagement.selectOrg': '机构',
    'transactionFee.groupManagement.selectOrg.placeholder': '请选择机构',
    'transactionFee.groupManagement.selected': '已选 {count} 个机构',

    'transactionFee.operationRecords.title': '操作记录',
};

export default transactionFee;
