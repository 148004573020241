const common = {
    /**
     * Action 通用操作
     */
    'common.action': '操作',
    'common.action.View': '查看',
    'common.action.Edit': '编辑',
    'common.action.Delete': '删除',
    'common.action.detail': '详情',
    'common.action.add': '新增',
    'common.action.update': '更新',
    'common.action.upload': '上传',
    'common.action.submit': '提交',
    'common.action.next': '下一步',
    'common.action.previous': '上一步',
    'common.action.success.tip': '操作成功',
    'common.action.fail.tip': '操作失败',
    'common.action.error.tip': '提示信息',
    'common.action.refresh': '刷新',
    'common.action.search': '搜索',
    'common.action.reset': '重置',
    'common.action.save': '保存',
    'common.action.history': '历史',
    'common.action.cancel': '取消',
    'common.action.export': '导出',
    'common.action.reject': '拒绝',
    'common.action.approve': '通过',
    'common.action.waiting': '等待中',
    'common.action.approved': '通过',
    'common.action.query': '查询',
    'common.action.saveEdit': '保存修改',
    'common.action.confirm': '确认',
    'common.action.log': '日志',
    'common.action.download': '下载',

    /**
     * Table 通用文案
     */
    'common.table.colomnTitle.index': '编号',
    'common.table.colomnTitle.name': '名称',
    'common.table.colomnTitle.version': '版本',
    'common.table.colomnTitle.updateTime': '更新时间',
    'common.table.colomnTitle.updatedBy': '更新人',
    'common.table.colomnTitle.createTime': '创建时间',
    'common.table.colomnTitle.createdBy': '创建人',
    'common.table.colomnTitle.status': '状态',
    'common.table.colomnTitle.approvedBy': '审批人',
    'common.table.colomnTitle.note': '版本备注',
    'common.table.colomnTitle.action': '操作',

    /**
     * 审批状态 通用文案
     */
    'common.text.approvalStatus': '审批状态',
    'common.text.approvalStatus.pendingApproval': '待审批',
    'common.text.approvalStatus.rejeced': '审批拒绝',
    'common.text.approvalStatus.approved': '审批通过',
    'common.text.approvalStatus.grayscaleRejeced': '灰度审批拒绝',
    'common.text.approvalStatus.grayscaleApproved': '灰度审批通过',
    'common.text.approvalStatus.rejected': '已拒绝',
    'common.text.high': '高',
    'common.text.medium': '中',
    'common.text.low': '低',

    /**
     * 上线状态 通用文案
     */
    'common.text.onlineStatus': '上线状态',
    'common.text.onlineStatus.notOnline': '未上线',
    'common.text.onlineStatus.online': '已上线',
    'common.text.onlineStatus.offline': '已下线',
    'common.text.onlineStatus.grayscaleOnline': '灰度已上线',
    'common.text.onlineStatus.grayscaleNotOnline': '灰度未上线',
    'common.text.onlineStatus.grayscaleError': '灰度异常',

    /**
     * 创建类型 通用文案
     */
    'common.text.createType': '创建类型',
    'common.text.createType.update': '更新方式创建',
    'common.text.createType.create': '新增方式创建',

    /**
     * Text 通用文案
     */
    'common.text.version': '版本',
    'common.text.Yes': '是',
    'common.text.No': '否',
    'common.text.all': '全部',
    'common.text.unknown': '未知',
    'common.text.remark': '备注',
    'common.pagination.total': '共 {total} 条数据',

    /**
     * 表单提示
     */
    'common.formInput.message.required': '请输入',
    'common.formInput.update.message.success': '更新成功',

    /**
     * Table org 通用文案
     */
    'common.table.colomnTitle.orgID': '机构ID',
    'common.table.colomnTitle.orgName': '机构名称',

    /**
     * Table 币种 通用文案
     */
    'common.table.colomnTitle.coin': '币种',
    'common.table.colomnTitle.amount': '金额',
    'common.table.colomnTitle.fiatCurrency': '法币金额',
    'common.table.colomnTitle.chainCoin': '所属链',
};
export default common;
