const siderBar = {
    'aladdin.home': 'Home',
    'aladdin.notification': 'Notification',
    'aladdin.transaction': 'Transaction',
    'aladdin.admin': 'Admin',
    'aladdin.crm': 'CRM',
    'aladdin.position': 'Position',
    'aladdin.configuration': 'Configuration',
    'aladdin.logout': 'Logout',
    'aladdin.logout.confirm': 'Are you sure to logout?',
    'aladdin.custody': 'Custody',
    'aladdin.wallet': 'Wallet',
    'aladdin.blockchain': 'Blockchain',
    'aladdin.kyt': 'KYT',
    'aladdin.risk_control': 'RiskControl',
    'aladdin.cobo_bonus': 'Cobo Bonus',
    'aladdin.manual_bookkeeping': 'Bookkeeping',
    // Admin
    'aladdin.kyc': 'KYC Audit',
    'admin.team': 'Team',
    'admin.policy': 'Policy',

    'configration.walletFinancialListPageConfigration': 'Financial List Configration',
    'configration.walletFinancialProductManagement': 'Financial Products',

    'configration.MortgageOrderManagement': 'Borrow order Management',
    'configration.walletUpdateMessage': 'Publish Alerts',
    'configration.publicMessage': 'Public Message',
    'configration.bannerConfig': 'Banner Configuration',
    'configration.rewardConfig': 'Operation Reward Configuration',
    'configration.financialClassifyConfig': 'Financial Classify Configuration',
    'configration.circleConfig': 'User Filter',
    'configration.namelistConfig': 'User Black & White List Configuration',
    'configration.mobileComponentConfig': 'App Configration',
    'configration.mortgageCustomizeConfig': 'Mortgage Customize Configuration',
    'configration.mortgageLoanCoinInfoConfig': 'Mortgage Loan Coin Info Configuration',
    'configration.mortgageCoinPairConfig': 'Mortgage Coin Pair Configuration',
    'configration.mortgageRepaymentConfig': 'Mortgage Repayment Configuration',
    'configration.mortgageDurationConfig': 'Mortgage Duration Configuration',
    'configration.mortgageOrderEdit': 'Mortgage Order Edit',
    'configration.mortgageBannerConfig': 'Borrow Banner Configuration',
    'configration.exchange': 'Exchange Configuration',
    'configration.hodlCustomizeConfig': 'Hodle Activity Configuration',
    'configration.hodlProductConfig': 'Hodle Product Configuration',
    'configration.hodlSupportCoinConfig': 'Hodle Support Coin Configuration',
    'configration.custodySettleRate': 'Custody Settle Rate',

    // CRM
    'crm.cobo-wallet-user': 'Cobo Wallet User',
    'crm.hd-wallet-user': 'HD Wallet User',
    'crm.vault-user': 'HD Wallet User',
    'crm.custody-user': 'Custody User',
    'crm.custodyUserSandbox': 'Custody Customer Sandbox',
    // 'crm.custodyUserProduct': 'Custody Customer Prod',
    // 'crm.sms': 'SMS Records',
    'crm.walletUserMangerManagement': 'User Manager',
    // 'crm.clienteleManagement': 'Clientele Management',

    // Notification
    'position.PositionManagement': 'Borrow Detail',
    'notification.unknown': 'What?',

    'transaction.pending-approval': 'Pending Approval',
    'transaction.internal-pending-approval': 'Internal Transfer Pending Approval',
    'transaction.new-internal-pending-approval': 'Internal Verify Fail Approval',
    'transaction.transaction-explorer': 'Transaction Explorer',
    'transaction.suspended-management': 'Suspended Coins',
    'transaction.local-transaction-explorer': 'Outbound Explorer',
    'transaction.custody-transaction-explorer': 'Custody Transaction',
    'transaction.custody-withdraw-explorer-detail': 'Custody Withdraw Detail',
    'transaction.custody-mpc-withdraw-explorer-detail': 'Custody MPC Withdraw Detail',
    'transaction.custody-withdraw-explorer': 'Custody Withdraw',
    'transaction.custody-mpc-withdraw-explorer': 'Custody MPC Withdraw',
    'transaction.portal-withdraw-explorer-detail': 'Portal Withdraw Detail',
    'transaction.portal-withdraw-explorer': 'Portal Withdraw',
    'transaction.portal-withdraw-explorer-reborn': 'Portal Transaction Manager',
    'transaction.portal-withdraw-explorer-reborn-detail': 'Portal Transaction Detail',
    // 'transaction.custody-withdraw-explorer.old': 'Custody Withdraw(old)',
    'transaction.custody-large-amount-withdraw-approve-config': '5M+ withdrawal process configuration',
    'transaction.inbound-transaction-management': 'Inbound Explorer',
    'transaction.mpc-inbound-transaction-management': 'MPC Inbound Explorer',
    'transaction.wallet-verify-transaction-explorer': 'Transaction Verification',
    'transaction.new-wallet-verify-transaction-explorer': 'Verify Fail Approval',
    'transaction.local-suspended-management': 'Suspended Coins',
    'transaction.local-danger-management': 'Danger Coins',
    'transaction.token-management': 'Token Management',
    'transaction.tx-detail': 'Transaction Details',
    'transaction.wallet-suspended-management': 'Suspended Coins',
    'transaction.custody-suspended-management': 'Suspended Coins',
    'transaction.custodyPushMessageManagement': 'Transaction Push',
    'transaction.threePartyHostingApplication': 'MPC three-party hosting application',
    'transaction.threePartyHostingList': 'MPC three-party hosting list management',

    'aladdin.user': 'person info',
    'aladdin.404': 'not fund',
    'aladdin.approvalSystem': 'Approval System',

    // Settings
    preparing: 'Preparing',
    Yes: 'Yes',
    No: 'No',
    Fix: 'fix',
    Edit: 'Edit',
    Reset: 'reset',
    More: 'more',
    Upload: 'upload',
    Close: 'close',
    Warning: 'Warning',
    Success: 'Success',
    Information: 'Information',
    Error: 'Error',
    Submit: 'Submit',
    Next: 'Next',
    Has: 'Yes',
    HasNot: 'None',
    Off: 'Off',
    On: 'On',
    Save: 'Save',
    Cancel: 'Cancel',
    Approval: 'Approval',
    Unbind: 'Unbind',
};

const blockchain = {
    'blockchain.deposit': 'Blockchain Deposit',
    'blockchain.tokenManagement': 'Token Management',
    'blockchain.manualCollectFunds': 'Fund Colletion',
    'blockchain.manualAdvanceTransaction': 'Advance Transaction',
};
const risk_control = {
    //    'risk_control.custodyUserAuthAdvance': 'Risk Event Advance',
    'risk_control.custodyUserAuthAdvanceNew': 'Risk Event Advance',
    'risk_control.custodyUserManager': 'User Info',
    'risk_control.custodyPubkeyManager': 'Pubkey Info',
    'risk_control.custodyStatementManager': 'Statement Info',
    'risk_control.custodyWithdrawalOrgReviewManager': 'Withdrawal Org Review',
    'risk_control.custodyCustomRuleManager': 'Custom Rule',
    'risk_control.authchainManager': 'Authchain Info',
};

const custody_tools = {
    'custody.addSupportedAssetCoin': 'Add Asset Coin',
    'custody.coinManage': 'Coin Manage',
    'custody.newCoinManage': 'Coin Manage',
    'blockchain.transactionRetryResend': 'Transaction Retry / Resend',
    'custody.financeConfig': 'Asset Management Product Configration',
};
const orgkey_speedup = {
    'custody.custodyOrgkeyAuthAdvance': 'Orgkey Advance',
};
const kyc_audit = {
    'kyc.wallet.audit': 'Wallet User Kyc',
    'kyc.custody.audit': 'Custody Kyc',
    'kyc.custody.auditnew': 'Custody Kyc New',
    'kyc.custody.auditDetail': 'Custody Kyc Detail',
    'kyc.custody.mpcAudit': 'Custody MPC KYC',
    'kyc.custody.mpcAudit.detail': 'Custody MPC KYC Detail',
};
const cobo_bonus = {
    'cobo_bonus.employee': 'Cobo Employee',
    'cobo_bonus.bonus': 'Cobo Bonus',
    'cobo_bonus.commission': 'Cobo Commission',
};
const manual_bookkeeping = {
    'manual_bookkeeping.transaction': 'Transaction',
    'manual_bookkeeping.address': 'Address',
};
const kyt = {
    'kyt.routeConfiguration': 'Route Configuration',
    'kyt.taskList': 'Task List',
};

const Sider = {
    ...siderBar,
    ...manual_bookkeeping,
    ...cobo_bonus,
    ...kyc_audit,
    ...orgkey_speedup,
    ...custody_tools,
    ...risk_control,
    ...blockchain,
    ...kyt,
};
export default Sider;
