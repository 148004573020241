const coinManage = {
    'custody.coinManage.siderBar.title': '币种管理',
    'custody.coinManage.table.header.chainCoin': '所属链',
    'custody.coinManage.table.header.fuzzyMatch': '模糊匹配',
    'custody.coinManage.table.header.precision': '精度',
    'custody.coinManage.table.header.minimumDepositThreshold': '最小可入帐金额',
    'custody.coinManage.table.header.visible': '可见范围',
    'custody.coinManage.table.header.displayCode': '展示代码',
    'custody.coinManage.table.header.confirmThreshold': '确认数阈值',
    'custody.coinManage.table.button.edit': '修改可见范围',
    'custody.coinManage.table.header.coinRisk': '币种风险',
    'custody.coinManage.placeholder.remark': '请输入备注（1-50字）',
    'custody.coinManage.visibility.fullyVisible': '全部可见',
    'custody.coinManage.visibility.fullyHidden': '全部不可见',
    'custody.coinManage.visibility.partiallyVisible': '部分可见',
    'custody.coinManage.visibleOrgs': '可见 Orgs',
};

export default coinManage;
