const url_prefix = '/v1/aladdin';
const developmentHost = {
    'https://home.aladdin-dev.1cobo.com': 'https://aladdin.dev.cobo.com', // 迁移完可删
    'https://home.aladdin-develop.cobo.com': 'https://aladdin.develop.cobo.com', // 迁移完可删
    'https://home-aladdin.dev.1cobo.com': 'https://aladdin.dev.cobo.com',
};
const config = {
    local: {
        origin: 'http://localhost:7000',
        client_id: '174254142584-4cf72udfsk8ftmcsuh0en1ofud38gv4c.apps.googleusercontent.com',
        tag: 'local',
        env: 'dev',
    },
    development: {
        // origin2: https://home.aladdin-dev.cobo.com
        // origin: 'https://aladdin.develop.cobo.com',
        origin: developmentHost[window.location.origin],
        client_id: '797171294681-nefvde223mce1s20f01jui7rj3q53k6h.apps.googleusercontent.com',
        tag: 'development',
        env: 'dev',
    },
    test: {
        origin: 'https://aladdin.sandbox.cobo.com',
        client_id: '640610477156-5g4fqrheadr7qpmfutu3fo54bfujpv62.apps.googleusercontent.com',
        tag: 'development',
        env: 'dev',
    },
    sandbox: {
        origin: 'https://aladdin.sandbox.cobo.com',
        client_id: '797171294681-irj6uq37fhic2807lp4p9od2p5c6jdqs.apps.googleusercontent.com',
        // origin: 'http://localhost:9001',
        // client_id: '797171294681-n8nbn9hetbrq8qr6ab0dpov9chm1id6u.apps.googleusercontent.com',
        tag: '测试环境',
        env: 'sandbox',
    },
    production: {
        origin: 'https://home.aladdin.1cobo.com',
        client_id: '797171294681-mrfug4vmj6ug5gaj7d790fma39iavma2.apps.googleusercontent.com',
        tag: '生产环境',
        env: 'prod',
    },
};
const currentConfig = config[process.env.REACT_APP_ENVIRONMENT];
const aladdin_host = currentConfig.origin + url_prefix;

export const client_id = currentConfig.client_id;
export const aladdin_host_x = aladdin_host;
export const envTag = currentConfig.tag;
export const env = currentConfig.env;
export default aladdin_host;
