const transactionFee = {
    'transactionFee.title': 'Transaction Fee',

    'transactionFee.feeStrategy.title': 'Fee Strategy',
    'transactionFee.categoryID': 'Category ID',
    'transactionFee.categoryID.placeholder': 'Please Enter Category ID',
    'transactionFee.feeType': 'Fee Type',
    'transactionFee.feeType.placeholder': 'Please Select Fee Type',
    'transactionFee.feeType.OutboundTransactionOn-Chain': 'Outbound Transaction On-Chain',
    'transactionFee.feeType.OutboundTransactionCompliance': 'Outbound Transaction Compliance',
    'transactionFee.feeType.OutboundTransactionGrossProfit': 'Outbound Transaction Gross Profit',
    'transactionFee.feeType.InboundTransactionCollection': 'Inbound Transaction Collection',
    'transactionFee.feeType.InboundTransactionCompliance': 'Inbound Transaction Compliance',
    'transactionFee.feeType.InboundTransactionGrossProfit': 'Inbound Transaction Gross Profit',
    'transactionFee.mainchainCoinFee': 'Fee(Mainchain Token)',
    'transactionFee.tokenCoinFee': 'Fee(Token)',
    'transactionFee.strategyPurpose': 'Strategy Purpose',
    'transactionFee.status.activate': 'Activated',
    'transactionFee.status.deactivate': 'Deactivated',

    'transactionFee.groupManagement.title': 'Group Management',
    'transactionFee.groupManagement.groupDes': 'Group Description',
    'transactionFee.groupManagement.groupDes.placeholder': 'Please Enter Group Description',
    'transactionFee.groupManagement.create': 'Add New Group',
    'transactionFee.groupManagement.edit': 'Edit Group',
    'transactionFee.groupManagement.lastOp': 'Last Operator',
    'transactionFee.groupManagement.selectOrg': 'Orgs',
    'transactionFee.groupManagement.selectOrg.placeholder': 'Please Select Orgs',
    'transactionFee.groupManagement.selected': 'Selected {count} Orgs',

    'transactionFee.operationRecords.title': 'Operation Records',
};

export default transactionFee;
